/* cabin-regular - latin */
@font-face {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/cabin/cabin-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Cabin"), local("Cabin-Regular"),
    url("../../public/fonts/cabin/cabin-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../public/fonts/cabin/cabin-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/cabin/cabin-v14-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/cabin/cabin-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/cabin/cabin-v14-latin-regular.svg#Cabin")
      format("svg"); /* Legacy iOS */
  font-display: fallback;
}
