@import "./cabin.css";
@import "./playfair.css";

.font-segoe {
  font-family: "Segoe UI", "Helvetica", "San Francisco", sans-serif;
}

.font-cabin {
  font-family: "Cabin", "Segoe UI", "Helvetica", "San Francisco", sans-serif;
}

.font-playfair {
  font-family: "Playfair Display", "Georgia", "Times New Roman", serif;
}
