/* playfair-display-regular - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-regular.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-600 - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: normal;
  font-weight: 600;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-500 - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: normal;
  font-weight: 500;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-700 - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-800 - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: normal;
  font-weight: 800;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 400;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-900 - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: normal;
  font-weight: 900;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-500italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 500;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-500italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-600italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 600;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-600italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-800italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 800;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-800italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-900italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 900;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-900italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-700italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-display: fallback;
  font-style: italic;
  font-weight: 700;
  src: url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/playfair/playfair-display-v20-latin-700italic.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
