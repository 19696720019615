@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* transition: 0.2s all; */
}

html {
  background-color: #fafafa;
}

body {
  overflow-y: scroll;
}

.card-shadow {
  box-shadow: 0 15px 35px -5px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
}

.card-shadow-light {
  box-shadow: 0 15px 35px -5px rgba(50, 50, 93, 0.03),
    0 5px 15px rgba(0, 0, 0, 0.015);
}

.blog-wrapper {
  display: grid;
  grid-template-columns: 1fr 70ch 1fr;
  grid-template-columns: 1fr min(70ch, 90%) 1fr;
}

.blog-wrapper > * {
  grid-column: 2;
}

.blog-full-bleed {
  width: 100%;
  grid-column: 1 / 4;
}

::selection {
  background: rgb(234, 210, 255); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgb(248, 232, 255); /* Gecko Browsers */
}

.__css_header-with-anchor {
  &:hover > span {
    opacity: 100%;
  }
  & > span:active {
    opacity: 100%;
  }
}

.__css_anchor {
  color: hsl(210, 75%, 48%);
  &:hover {
    color: hsl(210, 75%, 32%);
  }
}